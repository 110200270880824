/* global Component */
class footerSectionComponent extends Component {

    static name() {
        return "footerSectionComponent";
    }

    static componentName() {
        return "footerSectionComponent";
    }

    data() {
        return {
            year: new Date().getFullYear(),
            display: false,
            modalContent: '', 
        };
    }

    getComputed() {
        return {
            menuTitle:function (){
                return this.$store.getters.menuTitle;
            },
            menuSubTitle:function (){
                return this.$store.getters.menuSubTitle;
            },
            mustRender:function (){
                return this.$store.getters.settingsIsLoaded;
            },
            enableLK:function () {
                return this.$store.state.setting.EnableLK;
            },
            linkLK: function (){
                let link = "#";
                if(this.$store.state.setting.LKLink)
                    link = this.$store.state.setting.LKLink;
                return link;
            },
            enableIG:function () {
                return this.$store.state.setting.EnableIG;
            },
            linkIG: function (){
                let link = "https://instagram.com";
                if(this.$store.state.setting.IGLink)
                    link = this.$store.state.setting.IGLink;
                return link;
            },
            enableFB:function () {
                return this.$store.state.setting.EnableFB;
            },
            linkFB: function (){
                let link = "https://facebook.com";
                if(this.$store.state.setting.IGLink)
                    link = this.$store.state.setting.FBLink;
                return link;
            },
            enableTW:function () {
                return this.$store.state.setting.EnableTW;
            },
            linkTW: function (){
                let link = "https://twitter.com";
                if(this.$store.state.setting.IGLink)
                    link = this.$store.state.setting.TWLink;
                return link;
            },
            footerTemplateCode: function (){
                return this.$store.state.setting.FooterTemplate;
            },
            useFooter: function (){
                return this.$store.state.setting.UseFooter;
            },
            size() {
                return this.$store.state.setting.Switch === true ? 4 : 2;
            },
            groupedItems() {
                const groups = [];
                const footerRow = this.$store.state.setting.FooterRow || []; 
                const size =  this.size;
                for (let i = 0; i < footerRow.length; i += size) {
                    groups.push(footerRow.slice(i, i + size));
                }
                return groups;
            },
            TitleCol1() {
                return this.$store.state.setting.TitleColumn1;
            },
            TitleCol2() {
                return this.$store.state.setting.TitleColumn2;
            },
            TitleCol3() {
                return this.$store.state.setting.TitleColumn3;
            },
            TitleCol4() {
                return this.$store.state.setting.TitleColumn4;
            },
            policy(){
                return this.$store.state.setting.PrivacyPolicy;
            },
            terms(){
                return this.$store.state.setting.TermsAndConditions;
            },
            useModal: function (){
                return this.$store.state.setting.OptionTermsAndPolicy;
            },
        };
    }

    getMethods(){
        return {
            displayModal(option){
                this.display = true; 
                if(option === 'terms'){
                    this.modalContent = this.$store.state.setting.TermsClauseDescription;
                }else {
                    this.modalContent = this.$store.state.setting.PolicyClauseDescription;
                }
            }
        };
    }

    getTemplate() {
        return `<footer class="container-fluid" v-if="mustRender">
                <div v-if="useFooter" class="row justify-content-center py-3" style="background: white">
                    <div class="lean-overlay" v-if="display" @click="display = !display" id="materialize-lean-overlay-3"></div>
                    <div v-if="display" class=" modal modal-full modal-custom ">
                        <div class="modal-header">
                            <span class="close-modal" @click="display = !display" style="cursor: pointer">
                                <i class="close-icon material-icons">close</i>
                            </span>
                        </div>
                        
                        <div class="modal-content" style="background: rgb(245, 245, 245);position: relative;z-index: 100; padding: 2rem 1rem;">
                            <div v-html="modalContent" style="text-align: left"></div>
                        </div>
                    </div>

                    <div class="container" :class="size == 2 ? 'footer-container' : ''">
                        <div class="row-container" :class="'columns-'+size">
                            <span class="footer-title column-row" ><strong>{{TitleCol1}}</strong></span>
                            <span class="footer-title column-row" :class="size == 2 ? 'padding-left' : ''"><strong>{{TitleCol2}}</strong></span>
                            <span v-if="size === 4" class="footer-title column-row"><strong>{{TitleCol3}}</strong></span>
                            <span v-if="size === 4" class="footer-title column-row"><strong>{{TitleCol4}}</strong></span>
                        </div>
                        <div v-for="(group, index) in groupedItems" :key="index" class="row-container" :class="'columns-'+size">
                            <div v-for="(row, idx) in group" :key="row" class="column-row footer-content" :class="{'padding-left': size === 2 && idx % 2 !== 0}">
                                <a  class="contact" v-if="row.fields.Type == 0" :href="row.fields.PagePath" target="_blank">
                                    <i class="icon fas fa-map-marker-alt"></i> 
                                    {{row.fields.Text}}
                                </a>
                                <a class="contact" v-else-if="row.fields.Type == 1" :href="row.fields.PagePath" target="_blank">
                                    <i class="icon fas fa-phone-alt"></i> 
                                    {{row.fields.Text}}
                                </a>
                                <a class="contact" v-else-if="row.fields.Type == 2" :href="row.fields.PagePath" target="_blank">
                                    <i class="icon fas fa-envelope"></i> 
                                    {{row.fields.Text}}
                                </a>
                                <a v-else :href="row.fields.PagePath" class="">
                                    <p>{{row.fields.Text}}</p>
                                </a>
                            </div>
                        </div>
                        <div class="row-container columns-2 container-bottom-links" v-if="!useModal">
                            <a :href="policy" class="policy bottom-links">Políticas de privacidad</a>
                            <a :href="terms" class="terms bottom-links">Términos y condiciones</a>
                        </div>
                        <div class="row-container columns-2 container-bottom-links" v-else>
                            <a @click="displayModal('policy') " class="policy bottom-links">Políticas de privacidad</a>
                            <a @click="displayModal('terms')" class="terms bottom-links">Términos y condiciones</a>
                        </div>
                    </div>
                </div>
                 <template v-if="footerTemplateCode">
                    <htmlSectionComponent :templateCode="footerTemplateCode" />
                 </template>
                 <template v-else>
                    <div class="row justify-content-center py-3">
                         <div class="col-12 col-md-6 text-center text-md-left">
                            Copyright © {{year}} <a href="#" class="brand">{{menuTitle}}</a> All rights reserved.
                        </div>
                        <div class="col-12 col-md-6 text-center text-md-right mt-2 mt-md-0">
                            <span class="right">with <i class="icon fas fa-heart"></i> by <a href="https://oppen.io/" target="_blank" class="brand">öppen</a></span>
                        </div>
                    </div>
                </template>
            </footer>`;
    }
}

footerSectionComponent.registerComponent();